// react
import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import withClearCache from "./store/connector/withClearCache";
// routes
import { routes } from "./store/routes";
// others
import "./store/i18n";
import { initGA } from "./index";

import CookieConsent, { Cookies } from "react-cookie-consent";
import { COOKIE_EXPIRATION } from "./store/config";

// components
import PublicRoute from "./components/routes/PublicRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import ScrollToTop from "./components/routes/ScrollToTop";
// styles
import "./styles/fonts/style.css";
import "./styles/styles.css";
import "semantic-ui-css/semantic.min.css";
import "./styles/default.css";
import "./styles/dashboard.css";
import "./styles/public.css";
import "./styles/footer.css";
import "./styles/navbar.css";

// pages
// eslint-disable-next-line
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";
import AboutUs from "./pages/about-us/AboutUs";
import GeneralConditions from "./pages/general-conditions/GeneralConditions";
import Privacy from "./pages/privacy/Privacy";
import Error from "./pages/Error";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import Tutorial from "./pages/tutorial/Tutorial";
import Activation from "./pages/activation/Activation";
import Search from "./pages/search/Search";
import Basket from "./pages/payment-overview/Basket";
import PaymentFinish from "./pages/payment-overview/PaymentFinish";
import Activity from "./pages/activity/Activity";
import EventDetail from "./pages/event-detail/EventDetail";
import ResetPassword from "./pages/reset-password/ResetPassword";
import ConfirmResetPassword from "./pages/reset-password/ConfirmResetPassword";
import APSolidarity from "./pages/apsolidarity/APSolidarity";
import APBusiness from "./pages/apbusiness/APBusiness";
import APEvent from "./pages/apevent/APEvent";
import APwalkin from "./pages/APwalkin";

import { Button} from "semantic-ui-react";
import SuperModal from "./components/modals/SuperModal";
import CookieSettings from './components/CookieSettings';
import APOthers from "./pages/apothers/APOthers";


function App() {
    const { i18n } = useTranslation();
    const language = useSelector((state) => state.language);
    const [initLang] = useState(language);

    useEffect(() => {
        i18n.changeLanguage(initLang);
    }, [i18n, initLang]);

    // Google analytics initialization
    useEffect(() => {
        initGA();
    }, []);

    return (
        <>
            {/* <InstallPWA/> */}
            <ScrollToTop/>
            <Switch>
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.HOME}
                    component={Home}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.CONTACT}
                    component={Contact}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.ABOUT_US}
                    component={AboutUs}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.GENERAL_CONDITIONS}
                    component={GeneralConditions}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.PRIVACY}
                    component={Privacy}
                />

                <PublicRoute
                    restricted={true}
                    exact
                    path={routes.LOGIN}
                    component={Login}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.PASSWORD_RESET}
                    component={ResetPassword}
                />
                <PrivateRoute path={routes.DASHBOARD} component={Dashboard} />
                <PublicRoute
                    restricted={true}
                    path={routes.REGISTER}
                    component={Register}
                />
                <PrivateRoute
                    exact
                    path={routes.TUTORIAL}
                    component={Tutorial}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.SEARCH}
                    component={Search}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.ACTIVITY_DETAIL}
                    component={Activity}
                />

                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.ACTIVIY_EVENT_DETAIL}
                    component={EventDetail}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.BASKET}
                    component={Basket}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.PAYMENT_FINISH}
                    component={PaymentFinish}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.ACTIVATION}
                    component={Activation}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.PASSWORD_CONFIRM}
                    component={ConfirmResetPassword}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.AP_SOLIDARITY}
                    component={APSolidarity}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.AP_BUSINESS}
                    component={APBusiness}
                />

                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.AP_ACTION}
                    component={APwalkin}
                />

                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.AP_OTHERS}
                    component={APOthers}
                />

                <PublicRoute
                    restricted={false}
                    exact
                    path={routes.AP_EVENT}
                    component={APEvent}
                />

                <PublicRoute restricted={false} exact path={routes.ALL}>
                    <Error code={"404"} message={"Stránka nenájdená"} />
                </PublicRoute>
            </Switch>
            <CookieConsent
                location="bottom"
                buttonText="Súhlasím"
                cookieName="APcookie"
                onAccept={() => {
                    Cookies.set('APAnalytics', 'true', { expires: COOKIE_EXPIRATION })
                    document.location.reload()
                }}
                style={styles.cookie_container}
                buttonStyle={styles.cookie_button_style}
                contentStyle={styles.cookie_content}
                expires={COOKIE_EXPIRATION} //Number of days before the cookie expires.
                // overlay
            >
                <h4 style={{ fontWeight: "normal", fontFamily: "Metropolis Regular" }}>
                    Kliknutím na tlačidlo Súhlasím sa uložia technické a analytické súbory cookie, aby sme vám umožnili pohodlné používanie stránok a mohli merať funkčnosť našich stránok. Svoje preferencie môžete jednoducho upraviť kliknutím na Nastavenie.
                </h4>
                <SuperModal
                    centered={false}
                    closeIcon
                    trigger={
                        <Button type="button" size="large" style={{ position: "absolute", height: "45.5px", left: "12rem", bottom: "1rem" }} content={"Nastavenia"}/>
                    }
                    content={
                        <CookieSettings/>
                    }
                />
            </CookieConsent>
        </>
    );
}

const styles = {
    cookie_button_style: {
        background: "var(--primary)",
        color: "var(--white)",
        fontSize: 16,
        fontWeight: "bold",
        padding: "1rem 2rem",
        margin: "0px 1rem",
    },
    cookie_container: {
        background: "var(--light-grey)",
        display: "inline-block",
        padding: "1rem",
    },
    cookie_content: {
        margin: 0,
        marginBottom: "0.5rem",
        marginLeft: "1rem",
        width: "60%",
    },
};
export default withClearCache(App);

import React/*, { useState }*/ from 'react'

// components
import { Grid, Button, Header, Container } from 'semantic-ui-react'
// import APSolidarityInfoSection from "./APSolidarityInfoSection";
// import APSolidarityForm from './APSolidarityForm';


export default function APSolidarityInfo({ setContacting }) {

    return (
        <Container>
            <Grid className="section" relaxed>
                <Grid.Row>
                    <Grid.Column id="about-activitypoint">
                        <div style={{ color: "white", background: "rgb(53, 119, 223)", padding: "1rem", marginBottom: "2rem", borderRadius: "5px" }}>
                            <Header as="h2" textAlign="center" style={{ fontSize: "2rem", marginTop: "2rem", color: "white" }}>
                                Ako funguje ActivityPoint?
                            </Header>
                            <p style={{ textAlign: "center", width: "90%", margin: "1.5rem auto", fontSize: "1.15rem" }}>
                                Webová platforma ActivityPoint je primárne určená návštevníkom miest a obcí, ktorí nepoznajú ponuku aktivít v regióne.
                                ActivityPoint predstavuje mapu bodov v okolí návštevníka.
                                Návštevník je jednoduchým spôsobom, bez potreby registrácie informovaný, čo zaujímavé a hodnotné región ponúka. 
                                Body zobrazené na mape, znázorňujú pohybové, športové, edukačné, kultúrne a historické aktivity.
                                Mapa aktivít šetrí čas vyhľadávania a umožňuje okrem podrobného informovania aj navigáciu k jednotlivým bodom.
                            </p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <img style={{ width: "100%", borderRadius: "5px" }} src="/images/public/ap-mapa.PNG" alt="#ACTIVITYPOINT-1"/>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        {/* <Header as="h2"textAlign="center" style={{ fontSize: "2.5rem", marginTop: "3rem" }}>
                            Zviditeľnite svoje mesto/obec a <br/> začnite zbierať príspevky
                        </Header>
                        <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
                            Spokojní návštevníci tiež vedia priamo cez ich mobilné zariadenia jednoducho <br/> a transparentne 
                            finančne prispieť vašej obci na jej ďalší rozvoj a budovanie. 
                        </p> */}
                        <Header as="h2"textAlign="center" style={{ fontSize: "2rem", marginTop: "3rem" }}>
                            Dajte hodnotu miestam vo svojom meste/obci
                        </Header>
                        <p style={{ fontSize: "1.15rem", width: "80%", margin: "1.5rem auto", textAlign: "center" }}>
                            Vybudovali ste nové ihrisko? Otvorili nový skatepark ? Alebo máte jedinečné miesto vo svojom regióne, ktoré nájde návštevník len u vás ? Do mapy ActivityPoint vieme všetky tieto miesta pridať v podobe bodov na mape, čo umožňuje mestu alebo obci digitálne prezentovať to najzaujímavejšie čo ponúka a to 24 hodín denne, 7 dní v týždni, 365 dní v roku.
                            ActivityPoint je nástroj na úsporu finančných prostriedkov ekologickou formou, ktoré by inak  boli vynaložené na štandardné formy prezentácie v tlačovinách a bilboardoch. 
                            Všetko úsilie spojené s registrovaním, mapovaním, pridávaním bodov do mapy, leží na pleciach ActivityPoint, vašu administratívu nezaťažíme. 
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2} 
                    style={{ marginTop: '1rem' }}
                    centered
                >
                    <Grid.Column
                        mobile={12}
                        tablet={6}
                        computer={6}
                        style={{ marginTop: 0 }}
                    >

                        {/* <h2>
                            Registrácia obce/mesta
                        </h2>
                        <p>
                        Registrácia je bezplatná. Každé mesto alebo obec môže zdarma registrovať viacero objektov vo svojej samospráve a začať zbierať príspevky. 
                        S registráciou Vám radi pomôžeme. 
                        Vyplňte nezáväzný registračný formulár a odpovieme Vám na všetky prípadné otázky.
                        </p> */}
                        <h2>
                            Koľko to stojí peňazí ?
                        </h2>
                        <p>
                            <ul style={{ paddingLeft: "1rem", marginBottom: /*"1.5rem"*/0, listStyleType: "none"  }}>
                                <li class="custom-bullet">
                                    Cena 1 bodu zobrazeného na mape predstavuje 9,90 € (bez DPH) / mesiac.
                                </li>
                                <li class="custom-bullet">
                                    Vyplňte nezáväzný kontaktný formulár a my sa vám ozveme.
                                </li>
                            </ul>
                        </p>
                        <Button 
                            color="pink" 
                            style={{
                                marginTop: '1rem', 
                                marginBottom: '2rem',
                                padding: '1.2rem 2rem'
                            }}
                            onClick={() => setContacting(contactingPrev => !contactingPrev)}
                        >
                            {/* Nezáväzná registrácia obce/mesta */}
                            Nezáväzný registračný formulár
                        </Button>

                    </Grid.Column>


                    <Grid.Column
                        mobile={12}
                        tablet={6}
                        computer={6}
                        textAlign={"center"}
                        style={{height: 'auto'}}
                    >
                        <img src="/images/public/activity/activitypoint-side-image-apsolidarity.jpg" alt="#ACTIVITYPOINT" 
                            style={{
                                borderRadius: '4px'
                            }} 
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* <APSolidarityForm 
                opened={contacting}
                setOpenedFunc={setContacting}
            /> */}
        </Container>
    )
}

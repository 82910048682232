import React, { useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';

const ImageGallery = ({ images }) => {
    const [mainImage, setMainImage] = useState(images[0])

    return (
        <Grid>
            <Grid.Row columns="1" style={{ paddingBottom: 0 }}>
                <Grid.Column>
                    <Image src={mainImage} width="100%" height="250px" style={{ borderRadius: "4px", objectFit: "cover" }}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: "0.5rem", overflow: "hidden" }}>
                { images.map((item, idx) => 
                    <>
                        { item !== mainImage && 
                            <Grid.Column onClick={() => setMainImage(item)} style={{ cursor: "pointer", width: `${100 / (images.length - 1)}%` }} >
                                <Image src={item} width="98%" height="50px" style={{ borderRadius: "4px", objectFit: "cover" }}/>
                            </Grid.Column>
                        }
                    </>
                )}
            </Grid.Row>
        </Grid>
    );
};

export default ImageGallery;
// react
import React/*, { useEffect, useState }*/ from "react";
// components
import { Icon } from "semantic-ui-react";
// others
import "../../../styles/activitydetail.css";
// import { DistanceMatrixService } from "@react-google-maps/api";

// component
export default function EventHeader(props) {
    // state
    // const [destination, setDestination] = useState(null);
    // const [walking, setWalking] = useState(null);
    // const [driving, setDriving] = useState(null);

    // useEffect(() => {
    //     setDestination(props.destination);
    // }, [props.destination]);

    // const setDurationDetails = (result, type = "driving") => {
    //     let duration = result?.rows?.[0].elements?.[0].duration?.text;
    //     const distance = result?.rows?.[0].elements?.[0].distance?.text;

    //     switch (type) {
    //         case "driving": {
    //             duration = duration?.split(" ");
    //             if (typeof +duration?.[2] !== "undefined") {
    //                 if(duration){
    //                     duration.splice(2, 2);
    //                 }
    //             }
    //             duration = duration?.join(" ");

    //             setDriving({
    //                 duration,
    //                 distance,
    //             });
    //             break;
    //         }
    //         case "walking": {
    //             duration = duration?.split(" ");
    //             if (typeof +duration?.[2] !== "undefined") {
    //                 if(duration){
    //                     duration.splice(2, 2);
    //                 }
    //             }
    //             duration = duration?.join(" ");

    //             setWalking({
    //                 duration,
    //                 distance,
    //             });
    //             break;
    //         }
    //         default:
    //             break;
    //     }
    // };

    // template
    return (
        <div className="event-cal">
            <div>
                <div className="info-bar">
                    <strong className="name-event">{props.eventName}</strong>

                    {/*
                        <span className="divider-dott">·</span>

                        <div>
                        {props.rating}
                        <Rating
                            defaultRating={props.rating}
                            maxRating={5}
                            disabled
                            style={{ marginLeft: "0.6rem" }}
                        />
                    </div>
                    */}

                    <span className="divider-dott">·</span>

                    <div>
                        <Icon name="clock outline" />
                        {props.duration}
                    </div>
                </div>

                {/* <div>
                    <span>{walking?.distance || "0 km"} od Vás</span>

                    <span className="divider-dott">·</span>

                    <Icon name="male" />

                    <span>{walking?.duration || "0 min"}</span>

                    <span className="divider-dott">·</span>

                    <Icon name="car" />

                    <span>{driving?.duration || "0 min"}</span>
                </div> */}

                <div className="price-calc" style={{ display: (props?.timetable && props.timetable?.[0]?.event_type === "business") ? "none" : "block" }}>
                    {props.selected.is_discounted ? (
                        <div>
                            <div className="discount">
                                <strong>
                                    {Math.round(
                                        (1 - props.sale / props.cost) * -100
                                    )}
                                    %
                                </strong>
                            </div>

                            <div>
                                <span className="before">{props.cost}€</span>
                                <span className="price">{props.sale}€</span>
                            </div>
                        </div>
                    ) : (
                        <span className="price">{props.cost}€ </span>
                    )}
                </div>
                { (props?.timetable && props.timetable?.[0]?.event_type === "solidarity") &&
                    <>
                    <span><Icon name="circle info" color="pink" style={{ fontWeight: "bold", marginTop: "1rem" }}/>Dobrovoľný príspevok</span>
                    </>
                }
            </div>

            {/* {props?.position && destination && !driving && !walking && (
                <>
                    {window?.google?.maps && (
                        <>
                            <DistanceMatrixService
                                options={{
                                    destinations: [destination],
                                    origins: [props.position],
                                    travelMode: "DRIVING",
                                }}
                                callback={(response) =>
                                    setDurationDetails(response)
                                }
                            />

                            <DistanceMatrixService
                                options={{
                                    destinations: [destination],
                                    origins: [props.position],
                                    travelMode: "WALKING",
                                }}
                                callback={(response) =>
                                    setDurationDetails(response, "walking")
                                }
                            />
                        </>
                    )}
                </>
            )} */}
        </div>
    );
}

// react
import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// store
import {
    currencyOptions,
    durationOptions,
} from "../../../../store/services/helpers/settings";
import { LOADING_TIME } from "../../../../store/config";
import { activityService } from "../../../../store/services/ServiceActivity";
import {
    isEmpty,
    isEmptyErrorList,
    isNumberError,
    isSpecialOfferError,
    isDurationError,
    isPriceError,
} from "../../../../store/services/helpers/validation";
import { routes } from "../../../../store/routes";
// components
import Gallery from "./Gallery";
import ButtonDelete from "../../../../components/buttons/ButtonDelete";
import ModalConfirm from "../../../../components/modals/ModalConfirm";
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../../components/modals/ModalResponse";
import ActivityFormLabel from "./ActivityFormLabel";
import { Form, Dropdown, Divider } from "semantic-ui-react";
// others
import "react-image-crop/dist/ReactCrop.css";
import { convertToType } from "../../../../store/services/helpers/functions";


import { 
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg/lib/index.cjs';

// component
function ActivityForm(props) {
    let history = useHistory();
    const user = useSelector((state) => state.user)

    let durationConverted = 0;
    let orderTimeConverted = 0;

    if( props.data.id ){
        durationConverted = convertToType(props.data?.durationType, props.data?.duration)
        orderTimeConverted = convertToType(props.data?.minTimeToOrderType, props.data?.minTimeToOrder)
    }

    // state
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error message");

    // form attributes
    const [activityName, setActivityName] = useState(
        props.data.name ? props.data.name : ""
    );
    const [activityPlaces, setActivityPlaces] = useState([]);
    const [activity, setActivity] = useState(
        props.data.activity ? props.data.activity : ""
    );
    const [freePlaces, setFreePlaces] = useState(
        props.data.free_spots ? props.data.free_spots : ""
    );
    const [clientStornoRatio, setClientStornoRatio] = useState(props.data.client_storno_ratio ? props.data.client_storno_ratio : 100)
    const [providerStornoRatio, setProviderStornoRatio] = useState(props.data.provider_storno_ratio ? props.data.provider_storno_ratio : 100)
    const [description, setDescription] = useState(
        props.data.description ? props.data.description : ""
    );
    const [duration, setDuration] = useState(
        props.data.duration ? durationConverted : 1
    );
    const [durationType, setDurationType] = useState(
        props.data.durationType
            ? props.data.durationType.toString()
            : durationOptions[durationOptions.length - 1].key.toString()
    );
    const [orderTime, setOrderTime] = useState(
        props.data.minTimeToOrder ? orderTimeConverted : 1
    );
    const [orderTimeType, setOrderTimeType] = useState(
        props.data.minTimeToOrderType
            ? props.data.minTimeToOrderType.toString()
            : durationOptions[durationOptions.length - 1].key.toString()
    );
    const [price, setPrice] = useState(
        props.data.price ? props.data.price : "1.00"
    );
    const [currency, setCurrency] = useState(
        props.data.currencyType ? props.data.currencyType.toString() : "1"
    );
    const [discountPrice, setDiscountPrice] = useState(
        props.data.discountPrice ? props.data.discountPrice : "0.00"
    );
    const [discountCurrency, setDiscountCurrency] = useState(
        props.data.discountCurrencyType
            ? props.data.discountCurrencyType.toString()
            : "1"
    );
    const [mainImage, setMainImage] = useState(
        props.data.mainImage ? props.data.mainImage : null
    );
    const [images, setImages] = useState(
        props.data.gallery ? props.data.gallery : []
    );
    const [activities] = useState(props.activities);
    const [addresses] = useState(props.addresses);

    const [editPlacesInit, setEditPlacesInit] = useState(
        props.data.addresses ? true : false
    );

    useEffect(() => {
        for (let i = 0; i < currencyOptions.length; i++) {
            if (currencyOptions[i].text === props.country.currency) {
                setCurrency(currencyOptions[i].value.toString());
                setDiscountCurrency(currencyOptions[i].value.toString());
                break;
            }
        }
    }, [props.country]);

    /** component functions **/

    const handleAddressChange = (e, data) => {
        setActivityPlaces(data.value);
    };

    const handleActivityChange = (e, data) => {
        setActivity(data.value);
    };

    const handleDurationTypeChange = (e, data) => {
        setDurationType(data.value);
    };

    const handleOrderTimeTypeChange = (e, data) => {
        setOrderTimeType(data.value);
    };

    const handleCurrencyChange = (e, data) => {
        setCurrency(data.value);
    };

    const handleDiscountCurrencyChange = (e, data) => {
        setDiscountCurrency(data.value);
    };

    const deleteActivity = () => {
        setIsDeleteConfirmed(true);
    };

    const handleCancel = () => {
        setIsDeleteConfirmed(false);
    };

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    const handleConfirm = async () => {
        setIsDeleteConfirmed(true);
        setIsProcessing(true);
        if (await activityService.deleteCompanyActivity(props.data.id)) {
            setTimeout(function () {
                setIsProcessing(false);
                history.push(routes.MY_ACTIVITIES);
            }, LOADING_TIME);
        } else {
            setIsProcessing(false);
            setIsDeleteConfirmed(false);
            setErrorMessage("Počas vymazávania aktivity došlo k chybe!");
            handleErrorOpen();
        }
    };

    // set currency options
    const currencyOptionsCopy = [];
    for (let i = 0; i < currencyOptions.length; i++) {
        if (currencyOptions[i].text === props.country.currency) {
            currencyOptionsCopy.push(currencyOptions[i]);
            break;
        }
    }

    if (editPlacesInit) {
        props.data.addresses.map((address) => activityPlaces.push(address.id));
        setEditPlacesInit(false);
    }

    const handleSubmit = async (event) => {
        setIsProcessing(true);
        event.preventDefault();

        const data = {
            name: activityName,
            duration: duration,
            client_storno_ratio: parseFloat(clientStornoRatio),
            provider_storno_ratio: parseFloat(providerStornoRatio),
            price: parseFloat(price),
            durationType: parseInt(durationType),
            currencyType: parseInt(currency),
            free_spots: freePlaces ? parseInt(freePlaces) : 0,
            description: description,
            discountPrice: parseFloat(discountPrice),
            discountCurrencyType: parseInt(discountCurrency),
            minTimeToOrder: parseInt(orderTime),
            minTimeToOrderType: parseInt(orderTimeType),
            activity: activity,
            address: activityPlaces[0] ? activityPlaces[0] : null, // temporary fix to avoid null addresses
            addresses: activityPlaces,
            mainImage: mainImage,
            images: images,
        };

        let formError = false;

        // validate form fields
        if (
            isDurationError(duration) ||
            isNumberError(orderTime) ||
            isPriceError(price) ||
            isSpecialOfferError(discountPrice, price)
        ) {
            formError = true;
        }

        if (!formError) {
            if (props.data.id) {
                // send UPDATE REQUEST
                if (await activityService.editCompanyActivity(data,props.data.id)) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        history.push(routes.MY_ACTIVITIES);
                    }, LOADING_TIME);
                } else {
                    setErrorMessage("Počas ukladania dát došlo k chybe!");
                    handleErrorOpen();
                    setIsProcessing(false);
                }
            } else {
                // SEND CREATE REQUEST
                if (await activityService.saveCompanyActivity(data)) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        history.push(routes.MY_ACTIVITIES);
                    }, LOADING_TIME);
                } else {
                    setErrorMessage("Počas ukladania dát došlo k chybe!");
                    handleErrorOpen();
                    setIsProcessing(false);
                }
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        required
                        label="Názov aktivity"
                        placeholder="Názov aktivity"
                        value={activityName}
                        onChange={(e) => setActivityName(e.target.value)}
                    />
                    <Form.Field>
                        <label>
                            Druh aktivity <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <Dropdown
                            fluid
                            required
                            search
                            selection
                            placeholder="Vyhľadaj druh aktivity"
                            options={activities}
                            onChange={handleActivityChange}
                            value={activity}
                        />
                    </Form.Field>
                </Form.Group>

                <ActivityFormLabel
                    icon="write"
                    text="Popis aktivity"
                    required={false}
                />
                
                <Form.Group widths="equal" style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
                    <EditorProvider>
                        <Editor 
                            containerProps={{ style: { width: '100%', background: "white", minHeight: "250px" } }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        >
                            <Toolbar>
                                <BtnUndo />
                                <BtnRedo />
                                <Separator />
                                <BtnBold />
                                <BtnItalic />
                                <BtnUnderline />
                                <BtnStrikeThrough />
                                <Separator />
                                <BtnNumberedList />
                                <BtnBulletList />
                                <Separator />
                                <BtnLink />
                                <Separator />
                                <BtnClearFormatting />
                                <HtmlButton />
                            </Toolbar>
                        </Editor>
                    </EditorProvider>
                    {/* <Form.TextArea
                        className="textAreaField"
                        rows="15"
                        value={description}
                        placeholder="Popis aktivity"
                        onChange={(e) => setDescription(e.target.value)}

                    /> */}
                </Form.Group>

                <ActivityFormLabel
                    icon="clock"
                    text="Trvanie aktivity"
                    required={true}
                />
                <Form.Group widths="equal">
                    <Form.Input
                        placeholder="Trvanie aktivity"
                        value={duration}
                        required
                        onChange={(e) => setDuration(e.target.value)}
                        error={
                            !isEmpty(duration)
                                ? isDurationError(duration)
                                : false
                        }
                    />

                    <Form.Select
                        placeholder="Doba trvania"
                        options={durationOptions}
                        onChange={handleDurationTypeChange}
                        value={durationType}
                        required
                    />
                </Form.Group>

                

                <ActivityFormLabel
                    icon="map marker alternate"
                    text="Miesta konania aktivity"
                    required={true}
                />

                <Form.Group widths="equal">
                    <Dropdown
                        width={8}
                        fluid
                        placeholder="Miesta konania aktivity"
                        options={addresses}
                        required
                        multiple
                        selection
                        onChange={handleAddressChange}
                        value={activityPlaces}
                    />
                </Form.Group>

                <Divider/>

                <div style={{ display: "none" }}> 
                    <ActivityFormLabel
                        icon="shop"
                        text="Objednávka"
                        required={false}
                    />
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Základná cena"
                            placeholder="Cena"
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            error={!isEmpty(price) ? isPriceError(price) : false}
                        />
                        <Form.Select
                            fluid
                            label="Mena"
                            placeholder="Mena"
                            options={currencyOptionsCopy}
                            onChange={handleCurrencyChange}
                            value={currency}
                            required
                        />
                        <Form.Input
                            fluid
                            label="Cena po zľave"
                            placeholder="Cena"
                            type="number"
                            required
                            value={discountPrice}
                            onChange={(e) => setDiscountPrice(e.target.value)}
                            error={
                                !isEmpty(discountPrice)
                                    ? isSpecialOfferError(discountPrice, price)
                                    : false
                            }
                        />
                        <Form.Select
                            fluid
                            label="Mena"
                            placeholder="Mena po zľave"
                            options={currencyOptionsCopy}
                            onChange={handleDiscountCurrencyChange}
                            value={discountCurrency}
                            required
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Input
                            label="Počet voľných miest"
                            placeholder="Počet voľných miest"
                            value={freePlaces}
                            onChange={(e) => setFreePlaces(e.target.value)}
                            error={
                                !isEmpty(freePlaces)
                                    ? isNumberError(freePlaces)
                                    : false
                            }
                        />
                        <Form.Input
                            label="Minimálny čas objednávky"
                            required
                            value={orderTime}
                            onChange={(e) => setOrderTime(e.target.value)}
                            error={
                                !isEmpty(orderTime)
                                    ? isNumberError(orderTime)
                                    : false
                            }
                        />
                        <Form.Select
                            label="Doba minimálneho času"
                            options={durationOptions}
                            onChange={handleOrderTimeTypeChange}
                            value={orderTimeType}
                        />
                    </Form.Group>
                </div>


                { !user.is_town && 
                <div style={{ display: "none" }}>
                    <Divider/>

                    <ActivityFormLabel
                        icon="calendar times outline"
                        text="Podmienky reklamácie"
                        required={true}
                    />
                    <p> 
                        Nastavte percentuálnu hodnotu podielu peňazí z celkovej sumy aktivity, ktorá sa vráti na účet užívateľa v prípade odstúpenia/zrušenia termínu.
                    </p>

                    <Form.Group widths="equal">
                        <Form.Input
                            label="Zrušenie zo strany užívateľa (% podiel pre vratenie peňazí)"
                            required
                            value={clientStornoRatio}
                            onChange={(e, { value }) => {
                                if( value !== "" ){
                                    value = value.replace(',', '.')
                                }

                                if(value < 0) value = 0
                                if(value > 100) value = 100
                                setClientStornoRatio(value)
                            }}
                        />
                        <Form.Input
                            label="Zrušenie zo strany zadávateľa (% podiel pre vratenie peňazí)"
                            required
                            value={providerStornoRatio}
                            onChange={(e, { value }) => {
                                if( value !== "" ){
                                    value = value.replace(',', '.')
                                }

                                if(value < 0) value = 0
                                if(value > 100) value = 100
                                setProviderStornoRatio(value)
                            }}
                        />
                    </Form.Group>
                </div>
                }

                <Divider/>
                <Form.Group style={{ paddingBottom: "0.5rem" }}>
                    <Gallery
                        setImages={setImages}
                        images={images}
                        mainImage={mainImage}
                        setMainImage={setMainImage}
                    />
                </Form.Group>

                <Form.Group>
                    <ButtonSubmit
                        loading={isProcessing}
                        disabled={
                            isProcessing ||
                            !isEmptyErrorList([
                                activityName,
                                duration,
                                durationType,
                                activity,
                                price,
                                discountPrice,
                                discountCurrency,
                                orderTime,
                                clientStornoRatio,
                                providerStornoRatio
                            ]) ||
                            activityPlaces.length < 1
                        }
                        text="Uložiť aktivitu"
                    />
                    {props.data.id ? (
                        <ButtonDelete
                            loading={isDeleteConfirmed}
                            disabled={isProcessing}
                            onClick={deleteActivity}
                            text="Vymazať"
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>

                <ModalConfirm
                    open={isDeleteConfirmed}
                    header="Vymazanie aktivity"
                    content="Si si istý(á), že chceš vymazať túto aktivitu?"
                    confirmButton="Áno"
                    cancelButton="Nie"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />
            </Form>
            <ModalResponse
                open={errorOpen}
                onClose={handleErrorClose}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={handleErrorClose}
            />
        </Fragment>
    );
}

export default ActivityForm;

import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Header, Image } from 'semantic-ui-react';

const APOthers = () => {
    return (
        <div className="website" style={{ fontFamily: "Metropolis Regular" }}>
            {/* <Navbar linkBack="/" /> */}
            <div className="navigation" style={{ marginBottom: "1rem" }}>
                <span className="navigation-logo">
                    <RouteLink to={"/"}>
                        <Image className="navlogo" src="/images/logos/logo_dark_version.png" width="40%"/>
                    </RouteLink>
                </span>
            </div>

            <Header as="h2" content={"v príprave"} style={{ textAlign: "center"}}/>
        </div>

    );
};

export default APOthers;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Image, Container, Icon, Modal, /*Card, Label, Tab*/  } from 'semantic-ui-react';

const Home = () => {
    const [open, setOpen] = useState(false)


    return (
        <div className="website" style={{ fontFamily: "Metropolis Regular" }}>
            {/* Navigation Section */}
            <Container>
                <div className="navigation">
                    {/*<div className="link-section">
                         <Link to={"/"}>
                            <span className="navigation-item">Domov</span>
                        </Link>
                        <Link to={"about-us"}>
                            <span className="navigation-item">O náš</span>
                        </Link>
                        <span className="navigation-item dropdown">
                            <span 
                                className="nav-text"
                                onClick={() => setOpen(!open)}
                            >
                                Inzercia aktivít
                            </span>
                            <div className={`dropdown-list ${open && "open"}`}>
                                <span className="dropdown-list-item">Item 1</span>
                                <span className="dropdown-list-item">Item 2</span>
                                <span className="dropdown-list-item">Item 3</span>
                            </div>
                        </span>
                        <Link to={"contact"}>
                            <span className="navigation-item">Kontakt</span>
                        </Link> 
                    </div>*/}
                    <span className="navigation-logo">
                        <Image className="navlogo" src="/images/logos/logo_dark_version.png" width="40%"/>
                    </span>
                </div>


                {/* Action section */}
                <div className="section-for-actions">
                    <Link to={"search"} className="action-item">
                        <Image style={{ width: "110px", position: "relative", left: "1rem", top: "-6rem" }} src="images/icons/arrow.png"/>
                        <span style={{ fontSize: "1.5rem", position: "relative", top: "-4.8rem" }}>Hľadám <br/> activity</span>
                    </Link>
                    <span onClick={() => setOpen(!open)} className="action-item" style={{ position: "relative", top: "6rem" }}>
                        <span style={{ fontSize: "1.5rem" }}>Ponúkam <br/> activity</span>
                        <Image style={{ width: "170px", position: "relative", top: "-8.5rem" }} src="images/icons/circle-ap.png"/>
                    </span>
                </div>
            </Container>

            <Image src="images/public/homepage.jpg" width="100%;" height="auto" style={{ objectFit: "cover", maxHeight: "600px" }}/>

            <Container>
                <div className="content-area">
                    <p>
                        "Lebo pohyb je lepší ako sedieť dlho na rici"
                    </p>
                    <span>
                        Vladimír Buraš, CEO ActivityPoint
                    </span>
                </div>

                {/* Footer Section */}
                <div className="footer-section">
                    <div className="social-icon-set" style={{ marginBottom: "2rem" }}>
                        <a href="https://www.facebook.com/ActivityPoint.xyz/" target="_blank" rel="noopener noreferrer">
                            <Icon className="facebook-icon" name="facebook f" circular/>
                        </a>
                        <a href="https://www.instagram.com/activitypoint/" target="_blank" rel="noopener noreferrer">
                        <   Icon name="instagram" style={{ color: "#272d45" }}/>
                        </a>
                        <a href="https://www.tiktok.com/@activitypoint" target="_blank" rel="noopener noreferrer">
                            <Icon name="tiktok" style={{ color: "#272d45", height: "45px", width: "45px", position: "relative", top: "-0.8rem", background: "url(/images/icons/tiktok.svg)" }}/>
                        </a>
                        <a href="https://www.linkedin.com/company/activitypoint/" target="_blank" rel="noopener noreferrer">
                            <Icon name="linkedin" style={{ color: "#272d45" }}/>
                        </a>
                    </div>

                    {/* <div className="footer-navigation">
                        <Link to={"/"} className="footer-link">
                            <span>Domov</span>
                        </Link>
                        <Link to={"general-conditions"} className="footer-link">
                            <span>Všeobecné podmienky používania</span>
                        </Link>
                        <Link to={"privacy"} className="footer-link">
                            <span>Ochrana osobných údajov</span>
                        </Link>
                    </div>
                    <div className="footer-navigation" style={{ width: "400px" }}>
                        <Link to={"/about-us"} className="footer-link">
                            <span>O nás</span>
                        </Link>
                        <span className="footer-link"  onClick={() => setOpen(!open)}>
                            <span>Inzercia aktivít</span>
                        </span>
                        <Link to={"contact"} className="footer-link">
                            <span>Kontakt</span>
                        </Link>
                    </div> */}
                </div>   
            </Container>


            <Modal
                centered={false}
                size={"tiny"}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Modal.Content style={{ padding: "1.5rem", fontFamily: "Metropolis Regular", fontWeight: "bold" }}>
                    <div style={{ fontSize: "1.2rem", marginBottom: "1rem", fontWeight: "bold", color: "rgb(53, 119, 223)" }}>#APsolidarity</div>
                    <Link to={"/apsolidarity"} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <Image src="/images/icons/AP_Map_Pointer_solidarity_terms.svg" width="30px" style={{ marginRight: "1rem" }}/> 
                        <span style={{ color: "black" }}>
                            za 9,90 € mesačne zobrazuješ športoviská v meste/obci (čítaj viac)
                        </span>
                    </Link>

                    <div style={{ fontSize: "1.2rem", marginBottom: "1rem", marginTop: "2.5rem", fontWeight: "bold", color: "black" }}>#APbusiness</div>
                    <Link to={"/apinzert"} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "2rem", }}>
                        <Image src="/images/icons/AP_Map_Pointer_ap_inzert.svg" width="30px" style={{ marginRight: "1rem" }}/> 
                        <span style={{ color: "black" }}>#APinzert - za 19,90 € svietiš v mape celý mesiac (čítaj viac) </span>
                    </Link>
                    <Link to={"/apevent"} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "2rem"}}>
                        <Image src="/images/icons/AP_Map_Pointer_future_terms.svg" width="30px" style={{ marginRight: "1rem" }}/> 
                        <span style={{ color: "black" }}>#APevent - za 29,90 € blikáš v mape celý deň (čítaj viac)</span>
                    </Link>
                    <Link to={"/apwalkin"} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "2rem"}}>
                        <Image src="/images/icons/AP_Map_Pointer_business_terms.svg" width="30px" style={{ marginRight: "1rem" }}/> 
                        <span style={{ color: "black" }}>#APwalkIn</span>
                    </Link>
                    <Link to={"/ine"} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <Image src="/images/icons/AP_Map_Pointer_others.svg" width="30px" style={{ marginRight: "1rem" }}/> 
                        <span style={{ color: "black" }}>Iné</span>
                    </Link>
                    {/* <Tab 
                        fluid
                        className="module-selection"
                        menu={{ secondary: true, pointing: true }}
                        panes={[
                            { menuItem: '#APsolidarity', color: "pink", render: () => 
                                <Tab.Pane style={{ border: "none", fontFamily: "Metropolis Regular", fontWeight: "bold" }}>
                                    <Link to={"/apsolidarity"} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", color: "#3577df"}}>
                                        <Image src="/images/icons/AP_Map_Pointer_solidarity_terms.svg" width="30px" style={{ marginRight: "1rem" }}/> 
                                        Registrovať mesto/obec 
                                    </Link>
                                </Tab.Pane> 
                            },
                            { menuItem: '#APbusiness', color: "green", render: () => 
                                <Tab.Pane style={{ border: "none", fontFamily: "Metropolis Regular", fontWeight: "bold" }}>
                                    
                                </Tab.Pane>
                            },
                        ]}
                    /> */}
                </Modal.Content>
            </Modal>
        </div>
    );
};


export default Home;
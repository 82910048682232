import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
// components
import { Modal, Button, Icon, Header, Form, TextArea, Message } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
// store
import { isEmptyErrorList } from "../../store/services/helpers/validation";
import { commonService } from "../../store/services/ServiceCommon";
// others
//import Autocomplete from "react-google-autocomplete";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import moment from "moment";
import "moment/min/locales.min";


const SITE_KEY = "6LcE5ggbAAAAALYSdlwRjfN07-BGZQ7MFA4AA68b"


export default function ContactForm({ opened, setOpenedFunc }) {
    const [checked, setChecked] = useState(false);
    const [processing, setProcessing] = useState(false);
    // eslint-disable-next-line
    const [positive, setPositive] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const initFormData = {
        business_type: "#APinzert",
        provider_type: "Právnicka osoba",
        name: "",
        surname: "",
        function: "",
        phone: "",
        email: "",
        message: "",
        display_name: "",
        duration: "90",
        date_from: "",
        date_to: "",
        description: "",
        latitude: "",
        longitude: "",
        social_facebook: "",
        social_instagram: "",
        social_linkedin: "",
        social_tiktok: "",
        social_youtube: "",
    };

    const [formData, setFormData] = useState(initFormData);
    // eslint-disable-next-line
    const [captchaToken, setCaptchaToken] = useState('');


    const handleSubmit = async (event) => {
        setIsSent(false);
        event.preventDefault();
        setProcessing(true);
        
        const formDataToSend = {
            ...formData,
            date_from: moment(formData.date_from).format('YYYY-MM-DD'),
            date_to: moment(formData.date_to).format('YYYY-MM-DD'),
        }
        
        const request = await commonService.sendBusinessRegisterEmail(formDataToSend)
        
        if( request.status === 200 ){
            setPositive(true);
            setIsSent(true);
            setFormData(initFormData);
        } else {
            setPositive(false);
            setIsSent(true);
        }

        setProcessing(false);
    }


    useEffect(() => {
        loadReCaptcha(SITE_KEY);
    }, [])

    const verifyCallback = (token) => {
        setCaptchaToken(token);
    }

    const isDurationErrors = () => {
        let error = false

        if (isNaN(formData.duration)) return "Nesprávny vstup, vložte trvanie v počte dní"

        if (parseInt(formData.duration) < 90) return "Minimalna dĺžka trvania je 90 dní"

        return error
    }

    return (
        <Modal
            closeIcon
            open={opened}
            onClose={() => setOpenedFunc(false)}
            onOpen={() => setOpenedFunc(true)}
            size="tiny"
            style={{ borderRadius: 0}}
        >
            
            <Header content="Žiadosť o spoluprácu - #APinzert" />

            <Modal.Content>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group widths="equal">
                        <Form.Select
                            search
                            required
                            label="Typ poskytovateľa"
                            value={formData.provider_type}
                            onChange={(e, { value }) => setFormData(prev => ({...prev, provider_type: value }))}
                            options={[
                                { key: 1, value: "Právnicka osoba", text: "Právnicka osoba" },
                                { key: 2, value: "Živnostník", text: "Živnostník" },
                            ]}
                        />
                        <Form.Input
                            fluid
                            required
                            label="IČO"
                            value={formData.identification_number}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    identification_number: value,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Meno"
                            value={formData.name}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    name: value,
                                }))
                            }
                        />
                        <Form.Input
                            fluid
                            required
                            label="Priezvisko"
                            value={formData.surname}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    surname: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Funkcia/pozícia"
                            value={formData.function}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    function: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    email: value,
                                }))
                            }
                        />
                         <Form.Input
                            fluid
                            required
                            label="Telefónne číslo"
                            value={formData.phone}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    phone: value,
                                }))
                            }
                        />
                    </Form.Group>

                    

                    <Form.Input
                        fluid
                        required
                        label="Názov, pod ktorým Ťa klient na mape spozná"
                        value={formData.display_name}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                display_name: value,
                            }))
                        }
                    />
                    <Form.Input
                        required
                        fluid
                        label="Inzercia v trvaní (minimálna doba inzercie na mape v trvaní 90 dní)"
                        value={formData.duration}
                        error={isDurationErrors()}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                duration: value,
                            }))
                        }
                    />

                    <Form.Group widths="equal">
                        <DateInput
                            required
                            localization="sk"
                            label="Dátum od"
                            placeholder="DD.MM.YYYY"
                            dateFormat="DD.MM.YYYY"
                            value={formData.date_from}
                            onChange={(e, { value }) => setFormData(prev => ({...prev, date_from: value}))}
                            iconPosition="right"
                            animation="none"
                            closable
                            hideMobileKeyboard
                        />
                        <DateInput
                            required
                            localization="sk"
                            label="Dátum do"
                            placeholder="DD.MM.YYYY"
                            dateFormat="DD.MM.YYYY"
                            value={formData.date_to}
                            onChange={(e, { value }) => setFormData(prev => ({...prev, date_to: value}))}
                            iconPosition="right"
                            animation="none"
                            closable
                            hideMobileKeyboard
                        />
                    </Form.Group>  

                    <Header as="h3" content="GPS súradnice miesta"/>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Zemepisná šírka (latitude)"
                            value={formData.latitude}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    latitude: value,
                                }))
                            }
                        />
                         <Form.Input
                            fluid
                            required
                            label="Zemepisná dĺžka (longitude)"
                            value={formData.longitude}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    longitude: value,
                                }))
                            }
                        />
                    </Form.Group> 

                    <Header as="h3" content="Sociálne siete"/>
                    <Form.Input
                        fluid
                        label="Facebook"
                        placeholder="vložte odkaz (url)..."
                        value={formData.social_facebook}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                social_facebook: value,
                            }))
                        }
                    />
                    <Form.Input
                        fluid
                        label="Instagram"
                        placeholder="vložte odkaz (url)..."
                        value={formData.social_instagram}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                social_instagram: value,
                            }))
                        }
                    />
                    <Form.Input
                        fluid
                        label="LinkedIn"
                        placeholder="vložte odkaz (url)..."
                        value={formData.social_linkedin}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                social_linkedin: value,
                            }))
                        }
                    />
                    <Form.Input
                        fluid
                        label="Tiktok"
                        placeholder="vložte odkaz (url)..."
                        value={formData.social_tiktok}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                social_tiktok: value,
                            }))
                        }
                    />
                    <Form.Input
                        fluid
                        label="Youtube"
                        placeholder="vložte odkaz (url)..."
                        value={formData.social_youtube}
                        onChange={(e, { value }) =>
                            setFormData(prev => ({
                                ...prev,
                                social_youtube: value,
                            }))
                        }
                    />

                    <Form.Group widths="equal">
                        <Form.Field
                            required
                            control={TextArea}
                            label="Informácie, ktoré o Tebe majú svietiť na mape "
                            rows="3"
                            value={formData.description}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    description: value,
                                }))
                            }
                        />
                    </Form.Group>
                    
                    <Form.Group widths="equal">
                        <Form.Field
                            control={TextArea}
                            label="Správa k žiadosti (tu napíš všetko to, čo nám ešte chceš povedať)"
                            rows="3"
                            value={formData.message}
                            onChange={(e, { value }) =>
                                setFormData(prev => ({
                                    ...prev,
                                    message: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        label={{
                            children: (
                                <p>
                                    {`Súhlasím a bol som oboznámený so `}
                                    <Link
                                        to="/privacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        spracovaním osobných údajov
                                    </Link>
                                    .
                                </p>
                            ),
                        }}
                    />

                    <Message
                        onDismiss={() => setIsSent(false)}
                        positive={positive && isSent}
                        negative={!positive && isSent}
                        hidden={!isSent}
                        visible={isSent}
                        content={
                            <>
                                <h2>
                                    { positive && isSent
                                        ? "Správa bola úspešne odoslaná."
                                        : !positive && isSent
                                        ? "Chyba pri odosielaní správy."
                                        : ""
                                    }
                                </h2>
                                { positive && isSent 
                                    ? <p>Ďakujeme veľmi pekne za odoslanie žiadosti. <br/>Čoskoro Vás budeme kontaktovať.</p> 
                                    : !positive && isSent
                                    ? <p>
                                        <strong>Potencionálny problém:</strong> <br/>
                                        - neboli vyplnené všetky povinné údaje<br/>
                                        - zadaná emailová adresa nie je platná 
                                        </p>
                                    : ""
                                }

                                
                            </>
                        }
                    />

                    <Button 
                        fluid
                        color="green"
                        type="submit"
                        size="large"
                        loading={processing}
                        disabled={
                            !isEmptyErrorList([
                                formData.name,
                                formData.email,
                                formData.surname,
                                formData.function,
                                formData.phone,
                                formData.identification_number,
                                formData.provider_type,
                                formData.display_name,
                                formData.duration,
                                formData.date_from,
                                formData.date_to,
                                formData.latitude,
                                formData.longitude,
                                formData.description,
                            ]) ||
                            isDurationErrors() !== false ||
                            !checked ||
                            processing
                        }
                    >
                        <Icon name="checkmark" /> Odoslať žiadosť
                    </Button>



                    <ReCaptcha
                        sitekey={SITE_KEY}
                        action='verify'
                        verifyCallback={verifyCallback}
                    />
                </Form>
            </Modal.Content>
        </Modal>
    );
}

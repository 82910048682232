// react
import React from "react";
// components
import Info from "../../components/others/InfoBox";
import ContactForm from "./ContactForm";
import { Grid } from "semantic-ui-react";
// others
import "../../styles/contact.css";

// component
export default function ContactInfo() {
    // state
    // const [more, setMore] = useState(false);

    // template
    return (
        <Grid className="section">
            <Grid.Row columns={2} centered>
                <Info size={6}>
                    <div>
                        <h2>O webovej platforme</h2>
                        <p>
                            ActivityPoint je platforma, ktorej cieľom je ponúknuť aktivity pod 1 strechou 24/7/365 na online mape na webe www.activitypoint.xyz. Jedná sa o jedinečné (1), funkčné a férové riešenie na trhu s aktivitami pre užívateľov, zadávateľov aktivít, zároveň pre samosprávy. 
                        </p>
                        {/*
                            <Accordion>
                                <Accordion.Title
                                    active={more}
                                    index={0}
                                    onClick={() => setMore((prev) => !prev)}
                                >
                                <span className="toggle">
                                    <Icon name="dropdown" />
                                    Zistiť viac
                                </span>
                                </Accordion.Title>

                                <Accordion.Content active={more}>
                                    <p>
                                        A dog is a type of domesticated animal.
                                        Known for its loyalty and faithfulness, it
                                        can be found as a welcome guest in many
                                        households across the world.
                                    </p>
                                </Accordion.Content>
                            </Accordion>
                        */}
                    </div>

                    <div>
                        <h2>Prevádzkovateľ</h2>

                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column mobile={4} tablet={4} computer={3}>
                                    <ul>
                                        <li>NÁZOV:</li>
                                        <li>ADRESA:</li>
                                        <li className="space" />
                                        <li className="space" />
                                        <li className="space" />
                                        <li>IČO:</li>
                                        <li>DIČ:</li>
                                        <li>IČ DPH:</li>
                                        <li className="space" />
                                        <li>TELEFÓN:</li>
                                        <li>E-MAIL:</li>
                                        <li className="space" />
                                        <li>TELEFÓN:</li>
                                        <li>E-MAIL:</li>
                                    </ul>
                                </Grid.Column>
                                <Grid.Column
                                    mobile={12}
                                    tablet={12}
                                    computer={12}
                                >
                                    <ul>
                                        <li>
                                            <b> AB & Partners s.r.o</b>
                                        </li>
                                        <li>Námestie slobody 4</li>
                                        <li>066 01 Humenné</li>
                                        <li>Slovak republic</li>
                                        <li className="space" />
                                        <li>50711946</li>
                                        <li>2120473564</li>
                                        <li>SK 2120473564</li>
                                        <li className="space" />
                                        <li>
                                            <b>
                                                <a href="tel:+421905963959">
                                                    +421 905 963 959
                                                </a>
                                            </b>
                                        </li>
                                        <li>
                                            <b>
                                                <a href="mailto:info@activitypoint.xyz">
                                                    info@activitypoint.xyz
                                                </a>
                                            </b>
                                        </li>
                                        <li className="space" />
                                        <li>
                                            <b>
                                                <a href="tel:+421917118841">
                                                    +421 917 118 841
                                                </a>
                                            </b>
                                        </li>
                                        <li>
                                            <b>
                                                <a href="mailto:obchod@activitypoint.xyz">
                                                    obchod@activitypoint.xyz
                                                </a>
                                            </b>
                                        </li>
                                    </ul>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Info>
                <ContactForm />
            </Grid.Row>
        </Grid>
    );
}

// react
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// store
import { addToBasket, removeFromBasket, removeProductFromBasketConfiguration } from "../../../store/actions";
// components
import { Grid, Icon, Button, Label } from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
// others
import "../../../styles/activitydetail.css";

// component
export default function Event(props) {
    // init the date variable so we have date available all across the component
    const date = new Date();
    const persistentTimetable = packDuplicates(props.timetable);
    const dispatch = useDispatch()
    const basket = useSelector(state => state.basket)

    // state
    /* 
        Set initial state values so we can use it in our component
        timetable is a multidimensional array containing corresponding dates with different times
        when is a filter state - it changes whenever you select some other date or time
        choice - this one changes when you click on some term - it becomes active and selected
        openedList - contains corresponding information for the opened date
    */
    const [timetable, setTimetable] = useState([]);
    const [when, setWhen] = useState({
        date: date.toISOString().slice(0, 10),
        time: date.getHours() + 1 + ":00",
        dateTouched: false,
        timeTouched: false,
    });
    const [choice, setChoice] = useState({
        date: null,
        time: null,
    });
    const [openedList, setOpenedList] = useState({
        date: null,
    });

    /* 
        We have to init the timetable updated and properly mutated state so we can use it
        this one also updates whenever the timetable prop changes
     */
    useEffect(() => {
        setTimetable([...packDuplicates(props.timetable)]);
    }, [props.timetable]);

    // Function to select the specific event corresponding to it's query parameter - an ID
    
    const preSelectEvent = useCallback(
        (id) => {
            let foundItem = {};
            for (let i = 0; i < timetable.length; i++) {
                for (let j = 0; j < timetable[i].length; j++) {
                    if (timetable[i][j]?.id === +id) {
                        foundItem = { ...timetable[i][j] };
                        break;
                    }
                }
            }

            props.setSelected(foundItem)

            setChoice({
                date: foundItem?.date || null,
                time: foundItem?.time || null,
            });
            setOpenedList({
                date: foundItem?.date || null,
            });
        },
        // eslint-disable-next-line
        [timetable]
    );

    // select the specific event whenever the timetable changes
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const eventParam = queryParams.get("event");

        if (eventParam) preSelectEvent(eventParam);
        // eslint-disable-next-line
    }, [timetable, preSelectEvent]);

    /* NEW SIMPLER ALGORITHMS */

    const handleWhenChange = (_, data) => {
        switch (data?.name) {
            case "date": {
                setWhen((when) => ({
                    ...when,
                    date: data?.value || date.toISOString().slice(0, 10),
                    dateTouched: true,
                }));

                setTimetable([
                    ...filterTimetable(
                        {
                            ...when,
                            date:
                                data?.value || date.toISOString().slice(0, 10),
                        },
                        "date"
                    ),
                ]);

                break;
            }
            case "time": {
                setWhen((when) => ({
                    ...when,
                    time: data?.value || date.getHours() + 1 + ":00",
                    timeTouched: true,
                }));

                setTimetable([
                    ...filterTimetable(
                        {
                            ...when,
                            time: data?.value || date.getHours() + 1 + ":00",
                        },
                        "time"
                    ),
                ]);

                break;
            }

            default: {
                break;
            }
        }
    };

    function handleListOpening(date) {
        setOpenedList((opened) => ({
            date: date === opened?.date ? null : date,
        }));
    }

    function handleChoice(choice) {
        props.setSelected(choice)
        setChoice((_) => ({
            date: choice?.date || null,
            time: choice?.time || null,
        }));
    }

    /* 
        This algorithm destroys the main array containing terms
        and creates a new one - multidimensional with corresponding date and different time terms
     */
    function packDuplicates(timetable) {
        let newTimetable = [];
        let copyTimetable = [...timetable];

        newTimetable = copyTimetable
            .map((when, idx) => {
                const searchFromArr = copyTimetable.slice(idx + 1);

                const others = searchFromArr.filter((whenSecond) => {
                    if (whenSecond.date === when.date) {
                        copyTimetable.splice(idx + 1, 1);
                    }

                    return whenSecond.date === when.date;
                });

                const toReturn = others.length > 0 ? [when, ...others] : [when];

                return toReturn;
            })
            .filter((x) => x !== null);

        return newTimetable;
    }

    /* 
        children - holds the main JSX structure
        this component will render selectable elements - different terms
     */
    function renderOtherTimes(datetime) {
        let children;

        children = datetime.map((datetime) => {
            return (
                <React.Fragment key={datetime.date + datetime.time}>
                    <li>
                        <Button
                            className={`date child ${
                                datetime.date === choice.date &&
                                datetime.time === choice.time
                                    ? "chosen"
                                    : ""
                            }`}
                            onClick={() => handleChoice(datetime)}
                            active={true}
                        >
                            <div style={styles.itemOne}>
                                {datetime.date}  
                                <Label 
                                    size="tiny"
                                    style={{ 
                                        marginLeft: "0.5rem",
                                        color: datetime.event_type === "business" ? "black" : "white",
                                        background: datetime.event_type === "business" ? "#FFE200" : "none" 
                                    }} 
                                    color={ datetime.event_type === "business" ? undefined : datetime.event_type === "solidarity" ? "blue" : "green" }
                                >
                                    { datetime.event_type === "business" ? "#APinzert" : datetime.event_type === "solidarity" ? datetime?.is_highlighted ? "#APsolidarity - event" : "#APsolidarity" : "#APfuture" }
                                </Label>
                                { datetime.is_discounted && <Label size="tiny" color="black">Zľavnený</Label> }
                                { (basket.future_events.indexOf(datetime.id) !== -1 || basket.events.indexOf(datetime.id) !== -1) ? 
                                    <Label style={{ 
                                        position: "none",
                                        marginTop: "-1rem", 
                                        marginLeft: "2rem", 
                                        padding: 0, 
                                        color: "green", 
                                        fontSize: "1rem" 
                                    }} icon="shopping basket" color="white" size="tiny"></Label>
                                    : ""  
                                }
                            </div>

                            <div style={styles.itemTwo}>{datetime.time}</div>

                            <div style={styles.itemThreeCapacity}>
                                {datetime.capacity}/{datetime.people}
                            </div>
                        </Button>
                    </li>
                </React.Fragment>
            );
        });

        return children;
    }

    /* 
        Filtering algorithm - filters the "timetable" state and keeps only the values corresponding to
        the user's selection
     */
    function filterTimetable(when, whatChanged) {
        let updatedTimetable;

        updatedTimetable = persistentTimetable
            .map((date) => {
                return date.filter((datetime) => {

                    const toReturn =
                        (whatChanged === "date" || when.dateTouched
                            ? datetime.date === when.date
                            : true) &&
                        (whatChanged === "time" || when.timeTouched
                            ? datetime.time === when.time
                            : true);

                    return toReturn;
                });
            })
            .filter((val) => val?.length > 0);

        return updatedTimetable;
    }

    function resetFilters() {
        setWhen({
            date: date.toISOString().slice(0, 10),
            time: date.getHours() + 1 + ":00",
            dateTouched: false,
            timeTouched: false,
        });
        props.setSelected({})
        setChoice({
            date: null,
            time: null,
        });
        setOpenedList({
            date: null,
        });
        setTimetable(persistentTimetable);
    }

    // template
    return (
        <Grid>
            { (timetable[0]?.length === 1 && timetable[0][0]?.event_type === "solidarity") ? "" : 
                <Grid.Row columns={2} className="inputs">
                    <Grid.Column mobile={16} table={16} computer={8}>
                        Dátum<span className="pinkStar">*</span>
                        <DateInput
                            dateFormat="YYYY-MM-DD"
                            style={{ width: "100%" }}
                            name="date"
                            value={when.date}
                            onChange={handleWhenChange}
                        />
                    </Grid.Column>

                    <Grid.Column mobile={16} table={16} computer={8}>
                        Čas<span className="pinkStar">*</span>
                        <TimeInput
                            style={{ width: "100%" }}
                            name="time"
                            value={when.time}
                            onChange={handleWhenChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            }

            { console.log(timetable) }

            <div className="list">
                {timetable.map((data, idx) => (
                    <React.Fragment key={data?.[0].date + data?.[0].time}>
                        <li>
                            <Button
                                className={`date 
                                    ${
                                        data.length === 1 &&
                                        data[0].date === choice.date &&
                                        data[0].time === choice.time
                                            ? "chosen"
                                            : ""
                                    }
                                `}
                                onClick={() =>
                                    data.length > 1
                                        ? handleListOpening(data[0].date)
                                        : handleChoice(data[0])
                                }
                                active={true}
                            >
                                <div style={{ marginRight: "10px" }}>
                                    {idx + 1}.
                                </div>

                                <div style={styles.itemOne}>
                                    {data[0].date} { data.length === 1 && 
                                        <>
                                            <Label
                                                size="tiny"
                                                style={{ 
                                                    marginLeft: "0.5rem",
                                                    color: data[0].event_type === "business" ? "black" : "white",
                                                    background: data[0].event_type === "business" ? "#FFE200" : "none"
                                                }}
                                                color={ data[0].event_type === "business" ? undefined : data[0].event_type === "solidarity" ? "blue" : "green" }
                                            >
                                                { data[0].event_type === "business" ? "#APinzert" : data[0].event_type === "solidarity" ? data[0]?.is_highlighted ? "#APsolidarity - event" : "#APsolidarity" : "#APfuture" }
                                            </Label> 
                                            { data[0].is_discounted && <Label size="tiny" color="black">Zľavnený</Label> }
                                            { (basket.future_events.indexOf(data[0].id) !== -1 || basket.events.indexOf(data[0].id) !== -1) ? 
                                                <Label style={{ 
                                                    position: "none",
                                                    marginTop: "-1rem", 
                                                    marginLeft: "2rem", 
                                                    padding: 0, 
                                                    color: "green", 
                                                    fontSize: "1rem" 
                                                }} icon="shopping basket" color="white" size="tiny"></Label>
                                                : ""  
                                            }
                                        </>
                                    }
                                </div>

                                <div style={styles.itemTwo}>
                                    {data.length > 1 ? (
                                        <div style={styles.badge}>
                                            {data.length}
                                        </div>
                                    ) : (
                                        data[0].time
                                    )}
                                </div>

                                {data.length > 1 ? (
                                    <div style={styles.itemThreeArrow}>
                                        <Icon
                                            name={`angle ${
                                                data[0].date === openedList.date
                                                    ? "up"
                                                    : "down"
                                            }`}
                                            size="large"
                                            link
                                        />
                                    </div>
                                ) : (
                                    <div style={styles.itemThreeCapacity}>
                                        {data[0].capacity}/{data[0].people}
                                    </div>
                                )}
                            </Button>
                        </li>

                        {data.length > 1 &&
                            data[0].date === openedList.date &&
                            renderOtherTimes(data)}
                    </React.Fragment>
                ))}

                {timetable.length === 0 && <p>Aktivita neobsahuje žiadne dostupné termíny</p>}
            </div>

            { basket.future_events.indexOf(props.selected.id) === -1 && basket.events.indexOf(props.selected.id) === -1 ? 
                <Button
                    style={{ display: ((timetable[0]?.length === 1 && timetable[0][0]?.event_type === "business") || timetable.length === 0) ? "none" : "block" }}
                    onClick={(e) => dispatch(addToBasket(props.selected))}
                    className="add"
                    disabled={!choice?.date && !choice?.time}
                >
                    { (timetable[0]?.length === 1 && timetable[0][0]?.event_type === "solidarity") ? "PRISPIEŤ" : "PRIDAŤ DO KOŠÍKA" }
                </Button>
            : 
                <Button
                    style={{ background: "red", display: ((timetable[0]?.length === 1 && timetable[0][0]?.event_type === "business") || timetable.length === 0) ? "none" : "block" }}
                    className="add"
                    onClick={(e) => { dispatch(removeFromBasket(props.selected)); dispatch(removeProductFromBasketConfiguration(props.selected.id)); }}
                    disabled={!choice?.date && !choice?.time}
                >
                    ODOBRAŤ Z KOŠÍKA
                </Button>
            }
            <Button onClick={resetFilters} className="reset">
                Filter reset
            </Button>
        </Grid>
    );
}

const styles = {
    itemOne: {
        width: "62%",
        textAlign: "left",
    },
    itemTwo: {
        width: "30%",
        textAlign: "center",
    },
    itemThreeArrow: {
        width: "7%",
        textAlign: "center",
        color: "#d60c8c",
    },
    itemThreeCapacity: {
        width: "8%",
    },
    badge: {
        height: "1.8rem",
        width: "1.8em",
        borderRadius: "50%",
        background: "#ccc",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
    },
};

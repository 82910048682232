// react
import React from "react";
import { Link } from "react-router-dom";
// components
import { Icon, List, Grid } from "semantic-ui-react";

// component
function Footer() {
    // template
    return (
        <Grid className="footer" style={styles.footer}>
            <Grid.Row columns={3}>
                <Grid.Column mobile={16} tablet={10} computer={12}>
                    <div className="logoHead">
                        <img src="/images/logos/logo_big.png" alt="logo" />
                    </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={2}>
                    <a
                        href="https://www.mastercard.us/en-us/consumers/payment-technologies/securecode.html"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img
                            src="/images/general/mastercard_logo.png"
                            height="70"
                            alt="Mastercard"
                        />
                    </a>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={2}>
                    <a
                        href="https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/help?type=cardpay_vbv_help&lang=sk"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img
                            style={{ width: "30%", marginTop: "2rem" }}
                            src="/images/logos/logo_visa.gif"
                            alt="VISA"
                        />
                    </a>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} className="linking">
                <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={4}
                    className="company linkBlock"
                >
                    <h2>Spoločnosť</h2>
                    <List>
                        <List.Item>
                            <Link to="/about-us">O nás</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/contact">Kontakt</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/general-conditions">
                                Všeobecné podmienky používania
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/privacy">Ochrana osobných údajov</Link>
                        </List.Item>
                        {/*
                            <List.Item>
                                <a href="#linking">Newsroom</a>
                            </List.Item>
                            <List.Item>
                            <a href="#linking">Our offerings</a>
                            </List.Item>
                            <List.Item>
                            <a href="#linking">Investors</a>
                            </List.Item>
                            <List.Item>
                            <a href="#linking">Careers</a>
                            </List.Item>
                            <List.Item>
                            <a href="#linking">Blog</a>
                            </List.Item>
                        */}
                    </List>
                </Grid.Column>
                <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={4}
                    className="programs linkBlock"
                >
                    <h2>Programy</h2>
                    <List>
                        <List.Item>
                            <Link to="/apsolidarity" style={{ color: "#3577df" }}>#APsolidarity</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/apinzert" style={{ color: "#ffe200" }}>#APinzert</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/apevent" style={{ color: "#50d90d" }}>#APevent</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/apwalkin" style={{ color: "red" }}>#APwalkIn</Link>
                        </List.Item>
                    </List>
                </Grid.Column>

                {/* <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={4}
                    className="community linkBlock"
                >
                    
                    <h2>Community</h2>
                    <List>
                        <List.Item>
                            <a href="#linking">Associates</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Accessibility</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Diversity & Belonging</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Against Discrimination</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Invite friends</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Gift cards</a>
                        </List.Item>
                    </List>
                    
                </Grid.Column> */}

                <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={4}
                    className="support linkBlock"
                >
                    {/*
                    <h2>Support</h2>
                    <List>
                        <List.Item>
                            <a href="#linking">Help Center</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Cancellation options</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">COVID-19 Response</a>
                        </List.Item>
                        <List.Item>
                            <a href="#linking">Trust & Safety</a>
                        </List.Item>
                    </List>
                    */}
                </Grid.Column>
                <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={4}
                    className="help linkBlock"
                >
                    <h2>Kontakt</h2>
                    <List>
                        <List.Item>
                            <a href="mailto:info@activitypoint.xyz">
                                info@activitypoint.xyz
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href="tel:+421905963959">+421 905 963 959</a>
                        </List.Item>
                        <List.Item>
                            <a href="mailto:obchod@activitypoint.xyz">
                                obchod@activitypoint.xyz
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href="tel:+421917118841">+421 917 118 841</a>
                        </List.Item>
                    </List>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} width={16} className="socials " centered>
                <a
                    href="https://www.facebook.com/ActivityPoint.xyz/"
                    target="_blank"
                    className="icon"
                    rel="noopener noreferrer"
                >
                    <Icon name="facebook f" size="big" />
                </a>
                <a
                    href="https://www.instagram.com/activitypoint/"
                    target="_blank"
                    className="icon"
                    rel="noopener noreferrer"
                >
                    <Icon name="instagram" size="big" />
                </a>

                <a 
                    href="https://www.tiktok.com/@activitypoint"
                    target="_blank" 
                    className="icon"
                    rel="noopener noreferrer"
                >
                    <Icon name="tiktok" style={{ color: "#272d45", height: "45px", width: "45px", position: "relative", top: "2.3rem", background: "url(/images/icons/tiktok-white.svg)" }}/>
                </a>

                <a
                    href="https://www.youtube.com/channel/UCzfBevKgYpFL5SlH3ajROXQ?view_as=subscriber"
                    target="_blank"
                    className="icon"
                    rel="noopener noreferrer"
                >
                    <Icon name="youtube" size="big" />
                </a>
            </Grid.Row>
            <Grid.Row columns={1} width={16} className=" logoBottom" centered>
                <img src="/images/logos/logo_footer_bottom.png" alt="logo" />
            </Grid.Row>
        </Grid>
    );
}

const styles = {
    footer: {
        fontfamily: "Avenir Next LT Pro",
    },
};

export default Footer;

// react
import React from "react";
// components
import { Grid, Header, Icon } from "semantic-ui-react";

// component
function AboutUsSection({ icon, header, text }) {
    // template
    return (
        <Grid.Column
            textAlign="left"
            style={{ height: "auto", marginTop: "1rem" }}
            mobile={12}
            tablet={6}
            computer={4}
        >
            <Icon name={icon} size="big" color="black" />
            <Header as="h2" style={{ marginTop: "0.5rem" }}>
                {header}
            </Header>
            <p astyle={{ marginTop: "0.5rem" }}>
                {text}
            </p>
        </Grid.Column>
    );
}

export default AboutUsSection;
